<template>
  <footer
    class="footer"
    :class="{ 'bg-ol-dark-ink-200 border-t border-ol-dark-100': !useNew }"
    data-testid="footer"
  >
    <div class="footer__container">
      <div class="footer__container__content">
        <section>
          <div class="footer__section__logo">
            <LogoOctopusLegal
              v-if="isOctopusLegal"
              class="w-full"
              data-testid="logo-octopus-legal"
            />
            <LogoOctopusLegacy
              v-else
              class="w-full"
              data-testid="logo-octopus-legacy"
            />
          </div>
        </section>
        <section data-testid="about-us-links">
          <h3 class="footer__section__heading">ABOUT US</h3>
          <ul class="footer__section__links">
            <li v-for="link in aboutLinks" :key="link.name">
              <a :href="link.route">{{ link.name }}</a>
            </li>
          </ul>
        </section>
        <section data-testid="services-links">
          <h3 class="footer__section__heading">OUR SERVICES</h3>
          <ul class="footer__section__links">
            <li v-for="link in servicesLinks" :key="link.name">
              <a :href="link.route">{{ link.name }}</a>
            </li>
          </ul>
        </section>
        <section data-testid="resources-links">
          <h3 class="footer__section__heading">RESOURCES</h3>
          <ul class="footer__section__links">
            <li v-for="link in resourceLinks" :key="link.name">
              <a :href="link.route">{{ link.name }}</a>
            </li>
          </ul>
        </section>
      </div>
      <hr class="w-full text-ol-light-100" />
      <div
        v-if="isOctopusLegal"
        class="footer__container__content flex-wrap"
        data-testid="footer-container-content"
      >
        <p class="portrait:self-start self-end text-xs">
          Octopus Legal Services Ltd. is authorised and regulated by the
          Solicitors Regulation Authority (SRA ID: {{ sraId }})
        </p>
        <div
          class="flex flex-col sm:flex-row items-start gap-5 sm:gap-10 [&>*]:h-[5.3125rem]"
          data-testid="footer-logos"
        >
          <img :src="BCorp" alt="B Corp Logo" />
          <div class="rounded-lg overflow-hidden aspect-[1.7]">
            <!-- Start of SRA Digital Badge code -->
            <div style="max-width: 275px; max-height: 163px">
              <div
                style="
                  position: relative;
                  padding-bottom: 59.1%;
                  height: auto;
                  overflow: hidden;
                "
              >
                <iframe
                  title="SRA Verification Badge"
                  aria-label="Verification badge from Yoshki"
                  frameborder="0"
                  scrolling="no"
                  allowTransparency="true"
                  src="https://cdn.yoshki.com/iframe/55847r.html"
                  style="
                    border: 0px;
                    margin: 0px;
                    padding: 0px;
                    background-color: transparent;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                  "
                />
              </div>
            </div>
          </div>
          <!-- End of SRA Digital Badge code -->
        </div>
      </div>
      <div
        v-else
        class="footer__container__content"
        data-testid="footer-container-content"
      >
        <div class="footer__legal">
          <ul class="footer__section__social">
            <li
              v-for="socialIcon in socialIcons"
              :key="socialIcon.icon"
              class="social-icon"
            >
              <a
                :href="socialIcon.route"
                target="_blank"
                :aria-label="'Find Octopus Legacy on ' + socialIcon.icon"
              >
                <Component :is="socialIcon.icon" class="w-5 my-auto lg:w-6" />
              </a>
            </li>
          </ul>
          <div class="text-lg mb-4">
            &copy; Octopus Legacy {{ new Date().getFullYear() }}
          </div>
          <p class="text-2xs">
            {{ companyName }} (FRN: {{ companyFRN }}) is an appointed
            representative of Richdale Brokers and Financial Services Ltd which
            is authorised and regulated by the Financial Conduct Authority.
            {{ companyName }} is a company registered in England and Wales
            (Company Number {{ companyNumber }}), with its registered office at
            {{ olAddress }} and phone number {{ phoneNumber }}. Octopus Legacy
            Ltd is an online service providing legal forms and information.
            Octopus Legacy is not a law firm, but we work with law firms,
            including our subsidiary Octopus Legal Services (SRA No.
            {{ sraId }}) which is regulated by the SRA (Solicitors Regulation
            Authority), to deliver our Probate services.
          </p>
        </div>
        <div class="footer__certification" data-testid="footer-certification">
          <img :src="BCorp" alt="B Corp Logo" />
          <div class="footer__trustpilot">
            <div
              class="trustpilot-widget"
              data-locale="en-GB"
              data-template-id="5419b732fbfb950b10de65e5"
              data-businessunit-id="657080ebfa8c7e8d395f33bd"
              data-style-height="24px"
              data-style-width="100%"
              data-theme="dark"
            >
              <a
                href="https://uk.trustpilot.com/review/octopuslegacy.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Facebook from '@components/Icon/Facebook.vue';
import Instagram from '@components/Icon/Instagram.vue';
import LinkedIn from '@components/Icon/LinkedIn.vue';
import LogoOctopusLegacy from '@components/Icon/LogoOctopusLegacy.vue';
import LogoOctopusLegal from '@components/Icon/LogoOctopusLegal.vue';
import Twitter from '@components/Icon/Twitter.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    LogoOctopusLegal,
    LogoOctopusLegacy,
    LinkedIn,
    Twitter,
    Facebook,
    Instagram,
  },
  props: {
    isOctopusLegal: {
      type: Boolean,
      required: false,
      default: false,
    },
    useNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const aboutLinks = [
      { name: 'Our Story', route: window.route('about') },
      { name: 'Guides', route: window.route('advice.index') },
      { name: 'Careers', route: window.route('careers') },
      { name: 'Contact Us', route: window.route('contact') },
    ];
    const servicesLinks = [
      { name: 'Will Writing', route: window.route('will-writing') },
      { name: 'Life Insurance', route: window.route('life-insurance') },
      {
        name: 'Lasting Power of Attorney',
        route: window.route('lasting-power-of-attorney'),
      },
      { name: 'Bereavement Support Hubs', route: window.route('bereavement') },
      { name: 'Probate', route: window.route('probate') },
    ];

    const { hasBankWillPdf } = store.state.user;
    const resourceLinks = computed(() => {
      const links = [
        { name: 'Terms & Conditions', route: window.route('terms') },
        { name: 'Charities', route: window.route('charities') },
        { name: 'Funeral Director', route: window.route('funeral-directors') },
        {
          name: 'IFA',
          route: window.route('ifa'),
        },
      ];

      if (hasBankWillPdf) {
        links.unshift({
          name: 'Membership Login',
          route: window.route('member'),
        });
      }

      return links;
    });

    const BCorp = new URL(
      '/public/theme/img/B-Corp-Logo-White.webp',
      import.meta.url
    );

    const sraId = ref(window.config.sra_id);

    const toReturn = {
      BCorp,
      aboutLinks,
      servicesLinks,
      sraId,
      resourceLinks,
    };

    if (props.isOctopusLegal) {
      aboutLinks.push({
        name: 'Complaints',
        route: window.route('complaints-legal'),
      });
      return toReturn;
    } else {
      const socialIcons = ref([
        { icon: 'LinkedIn', route: window.config.company_social_linkedin },
        { icon: 'Facebook', route: window.config.company_social_facebook },
        { icon: 'Instagram', route: window.config.company_social_instagram },
        { icon: 'Twitter', route: window.config.company_social_twitter },
      ]);

      const companyName = ref(window.config.company_name);
      const companyFRN = ref(window.config.company_firm_reference_number);
      const companyNumber = ref(window.config.company_number);
      const olAddress = ref(window.config.company_octopus_legacy_address);
      const phoneNumber = ref(window.config.contact_phone_print);

      return {
        ...toReturn,
        socialIcons,
        companyName,
        companyFRN,
        companyNumber,
        olAddress,
        phoneNumber,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  @apply w-full py-2 sm:py-3;
}

.footer__container {
  @apply flex
  flex-col
  justify-center items-center self-stretch
  gap-14 xl:gap-16 portrait:gap-10
  w-full
  bg-ol-dark-ink-200
  rounded-2xl
  pt-8 xs:pt-16 xl:pt-20
  pb-8 sm:pb-10 lg:pb-14 xl:pb-16
  px-5 xs:px-7 sm:px-10 md:px-7 lg:px-9 xl:px-[3.25rem];
  > * {
    @apply max-w-[96rem];
  }
}

.footer__container__content {
  @apply flex
  flex-col lg:flex-row
  sm:gap-y-8 portrait:gap-y-8
  justify-between items-start
  w-full
  text-ol-dark-10;
}

.footer__section__logo {
  @apply flex
  justify-start align-middle
  w-36 lg:w-60 portrait:w-60;
}

.footer__section__heading {
  @apply text-ol-dark-10
  text-xl
  sm:text-left
  mb-3 sm:mb-8;
}

.footer__section__links {
  @apply flex
  flex-col
  gap-y-3
  text-xl sm:text-lg
  sm:text-left
  text-ol-pink;
}

.footer__section__social {
  @apply flex gap-x-4 justify-start sm:items-end mb-4;
}

.footer__legal {
  @apply text-left sm:text-left lg:w-1/2;
}

.footer__rating {
  @apply flex flex-row items-start mt-6 mb-4 sm:mb-0;
}

.footer__certification {
  @apply flex
  justify-between
  lg:gap-x-4
  lg:flex-row-reverse
  self-start lg:self-end
  w-full sm:w-fit
  [&>img]:w-12;
}

.footer__trustpilot {
  @apply flex flex-col sm:flex-row gap-x-2 self-center;
}
</style>
